@import "~gridstack/dist/gridstack.css";
@import "~gridstack/dist/gridstack-extra.css";
@import "~antd/dist/antd.css";

@import url("https://fonts.googleapis.com/css2?family=Signika&family=Raleway&family=Kanit:wght@100;200;300;400;500;600;700&family=Rowdies:wght@300;400;700");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.logo {
  max-width: 100%;
  padding: 15px 20px;
  text-align: center;
}

body {
  font-family: "Raleway", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Kanit", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans",
    Arial, sans-serif;
  color: #545f73;
  font-weight: 500;
}

h1 {
  font-size: 2rem;
  font-weight: 700;
}

p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #7e879d;
}

.otp-field {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.ant-input .otp-field__input {
  width: 32;
  height: 32;
  margin: 10px;
  border: 1px solid #aaa;
  border-radius: 5px;
  color: #0f8dd6;
  box-sizing: border-box;
  font-size: 16;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s;
}

.otp-field__input::-webkit-outer-spin-button,
.otp-field__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.otp-field__input[type="number"] {
  -moz-appearance: textfield;
}
.otp-field__input:focus {
  border-color: #0f8dd6;
  outline: none;
}
.otp-field__input:disabled {
  background-color: #f3f3f3;
  color: #aaa;
}

.card-base {
  background-color: #fff;
  padding: 24px;
}

.rounded-corners-large {
  border-radius: 25px;
}

.shadow-spread {
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
}

.shadow-small {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.site-layout .ant-layout-header {
  background: #fff;
}

.ant-btn {
  border-radius: 8px;
}

.ant-layout-sider-zero-width-trigger {
  top: 20px;
}

/* Side menu */
.ant-menu-inline {
  border: none;
}

.ant-menu .ant-menu-item {
  color: #8e98a6;
  font-weight: 700;
  border-radius: 8px;
  border-right: none;
}

.ant-menu .ant-menu-item.ant-menu-item::after {
  display: none;
}

.ant-menu .ant-menu-item:hover {
  color: #306ba3;
}

.ant-menu .ant-menu-submenu {
  color: #8e98a6;
  font-weight: 700;
}

.ant-menu .ant-menu-submenu .ant-menu-item.ant-menu-item-only-child {
  margin: 0;
}

.ant-menu .ant-menu-submenu .ant-menu-submenu-arrow {
  color: #8e98a6;
}

.ant-menu .ant-menu-sub.ant-menu-inline {
  border-radius: 8px;
}

.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 10px;
  font-size: 0.9em;
  border-bottom: 1px solid #eeeeee;
}

.ant-menu .ant-menu-item.ant-menu-item-selected {
  color: #306ba3;
  background-color: #f7f7f7;
  width: 100%;
}

.ant-menu .ant-menu-item.ant-menu-item-selected::after {
  display: none;
}

.ProseMirror-focused {
  outline: none;
}

.tb-table-header {
  padding: 8px 0;
}

.tb-table-row {
  padding: 8px 0;
  min-height: 48px;
  background-color: #fff;
  transition: all 0.2s;
  margin-bottom: 1px;
  border-radius: 8px;
  cursor: pointer;
}

.tb-table-row:hover {
  background-color: #f7f7f7;
}

.tb-table-row .ant-input {
  border-color: transparent;
  border-radius: 3px;
}

.ant-input {
  border-color: #eaeaea;
  border-radius: 8px;
}

.tb-table-row .ant-input:hover {
  border-color: #1890ff;
}

.tb-title-input .ant-input {
  border-color: transparent;
  border-radius: 8px;
}

.tb-title-input .ant-input:hover {
  border-color: #1890ff;
}

.tb-select .ant-select .ant-select-selector {
  border-color: transparent;
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
}

.tb-select .ant-select .ant-select-selector:hover {
  border-color: #1890ff;
}

.ant-modal-content {
  border-radius: 25px;
}

.ant-popover-inner {
  border-radius: 25px;
}

.tb-pricing-card {
  display: flex;
  align-items: stretch;
  flex-flow: column wrap;
  min-height: 550px;
  max-width: 300px;
  border-radius: 20px;
  padding: 32px 24px;
  background: #fff;
}

.tb-table-title {
  font-size: 26px;
  font-weight: 700;
  margin: 0 0 0 23px;
}

.tb-help-open {
  padding-right: 300px;
  transition: all 0.25s;
}
